.login-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
    color: #333;
    margin-bottom: 20px;
}

.login-form {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 10px;
    
}

.login-input {
    display: flex;
    padding: 10px;
    align-self: center;
    width: 100%;
}

.login-button {
    padding: 10px 15px;
    background-color: #28a745;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
    align-self: center;
    width: 100%;
}

.login-button:hover {
    background-color: #218838;
}
.error-message {
    color: red;
    margin-bottom: 10px;
}
