/* GDBAudit.css */

/* Container and Header styles */
.GDBAudit-view-container {
    background-color: var(--background-color);
    padding: 2% 5%;  /* Vertical and Horizontal padding */
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0,0,0,0.1); /* subtle box shadow for depth */
}

.GDBAudit-logo1, .GDBAudit-logo2 {
    width: 80px; /* Adjust based on your logo size */
    height: auto;
}


/* Content styling */
.GDBAudit-content {
    font-size: 1em;
    line-height: 1.6;
    color: var(--text-color);
    margin-left: 20px;
    margin-right: 20px;
}
.section-content {
    margin-top: 30px;
}

.section-item p {
    margin-top: 10px;
    margin-bottom: 5px;
}

.GDBAudit-section-title {
    font-size: 2em;
    color: var(--primary-color);
    margin-top: 20px;
    margin-bottom: 20px;
    border-bottom: 2px solid var(--primary-color); /* Underline for section titles */
    padding-bottom: 10px;
}

.section-title h2{
    font-size: 1.4em;
    font-weight: 600;
    color: var(--primary-color);
    margin-top: 20px;
    margin-bottom: 20px;
}

.GDBAudit-description {
    margin-bottom: 30px;  /* Space between each section */
}

.GDBAudit-paragraph {
    margin-bottom: 20px; /* Space between paragraphs */
    margin-left: 10px;
    text-align: justify; /* Justified text for a cleaner look */
}

/* Optional styling for tables, lists, or other elements can be added as per the content layout */

/* Responsiveness */
@media (max-width: 768px) {
    .GDBAudit-header {
        flex-direction: column;
        align-items: center;
    }

    .GDBAudit-logo1, .GDBAudit-logo2 {
        margin-bottom: 20px;
    }

    .GDBAudit-title {
        font-size: 1.5em;
        text-align: center;
    }
}
