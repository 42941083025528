.footer {
    display: flex;
    flex-direction: column;
    background-color: #222;
    color: #aaa;
    font-size: small;
    padding: 10px 0;
    text-align: center;
    height: var(--footer-height);
    vertical-align: middle;
}

.footerNavContainer {
    display: flex;
    flex-direction: row;
    align-items: center; /* Align direct children vertically */
    justify-content: space-around;
    color: #aaa;
    font-size: medium;
    margin-bottom: 10px;
    width: 100%;
}

.footerNavDiv {
    width: 100%;
}

.socialContainer {
    display: flex;
    flex-direction: row;
    vertical-align: middle;
    align-items: center;
    width: 100%;
}

.footerNavLink {
    margin-left: 10px;
    margin-right: 10px;
    color: var(--link-header-solid-text-color);
    font-size: medium;
    text-decoration: none; /* Added to remove underline */
    padding: 5px 5px;
}

.footerNavLink:hover {
    background-color: var(--link-header-solid-hover-bg-color);
    color: var(--link-header-solid-hover-text-color);
    border-bottom: none; /* Remove the border on hover if not needed */
}

.socialTittle {
    color: var(--link-header-solid-text-color);
}