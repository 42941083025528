.auth-view-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.auth-container {
    display: flex;
    justify-items: center;
    flex-direction: column;
    width: 30%;
    min-width: 300px;
    align-items: stretch;
    justify-content: center;
    align-items: center;
}

.reCaptcha-container {
    justify-content: center;
    align-items: center;
    flex: 1 0 auto;
    font-size: smaller;
    width: 30%;
    color: lightgray;
    text-align: center;
}

.reCaptcha-container a{
    color: rgb(52, 197, 245);
    font-size: smaller;
}

.login-container {
    width: 100%;
    margin-top: 10px;
}

.reset-password-container {
    width: 100%;
    margin-top: 10px;
    text-align: center;
}
.reset-password-container Link{
    color: mediumslateblue;
    font-size: small;
}