

.register-message {
    display: flex;
    width: 100%;
    height: 40px;
    margin-top: 30px;
    font-size: 1.2rem;
    font-weight: 500;
    margin-left: 30px;
}

.downloads-tool-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-left: 30px;
}

.downloads-tool-container {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    margin-left: 20px;
}

.downloads-product-title {
    font-size: 1.2rem;
    font-weight: 700;
}

.downloads-tool-name {

    font-size: 1rem;
    font-weight: 500;
    margin-right: 20px;
}
