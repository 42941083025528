.profile-management-view {
    margin-left: 10%;
    margin-right: 10%;
}

.profile-management-view .error {
    color: var(--error-color);
}

.profile-form-container {
    display: flex;
    flex-direction: column;
    margin-top: 2px;
    margin-bottom: 2px;
    max-width: 640px;
    min-width: 400px;
    justify-content: center;
}

.profile-form-container .form-field-container {
    display: flex;
    flex-direction: row;
    margin-top: 3px;
    margin-bottom: 3px;
}

.profile-form-container .form-field-container label {
    display: flex;
    width: 160px;
    align-items: center;
    min-width: 160px;
}

.profile-form-container .form-field-container input {
    width: 100%;
    max-width: 360px;
    margin-top: 0px;
    margin-bottom: 0px;
}
.profile-form-container button {
    align-self: normal;
    font-size: large;
}

.modify-field-div, .profile-form-container .modify-field-button {
    font-size: small;
    padding: 3px;
    margin: 0px;
    width: 35px;
    min-width: 35px;
    height: 20px;
    align-self: center;
}


.profile-management-view .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
}

.profile-management-view .modal-content {
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 400px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.profile-management-view .close-button {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}


.disabled-button {
    background-color: #C0C0C0; /* Gray color for disabled button */
    color: #808080; /* Darker gray for the text to make it appear "faded" */
    cursor: not-allowed; /* Indicate the button is not clickable */
    border: 1px solid #A9A9A9; /* Border color matching the background */
    padding: 8px 16px; /* Same padding as enabled button */
    font-size: 1rem; /* Same font size as enabled button */
}

.disabled-button:hover {
    background-color:  #C0C0C0; /* Slightly darker blue for hover effect */
}

.profile-form-container .disabled-modify-field-button {
    font-size: small;
    padding: 3px;
    margin: 0px;
    width: 35px;
    min-width: 35px;
    height: 20px;
    align-self: center;
    background-color:  #C0C0C0; /* Slightly darker blue for hover effect */
}

.password-change-div {
    display: flex;
    flex-direction: row;
}
.password-change-button {
    font-size: small;
    padding: 3px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 3px;
    margin-right: 3px;
    width: 40px;
    min-width: 40px;
    height: 20px;
    align-self: center;
}