.landing-view-container {
    display: flex;
    align-items: center; /* Vertically centers the children */
    width: 100%;
    min-width: 1200px;
    height: 100vh; /* Takes the full viewport height */
    background-size: cover; /* Ensures the background image covers the entire container */
    background-position: center; /* Centers the background image */
    background-blend-mode: overlay; /* This will blend the background color with the image */
    background-color: rgba(255, 255, 255, 0.2); /* Semi-transparent white overlay to ensure text readability */
}

.landing-image {
    height: 800px;
    margin-right: 30px;
}

.landing-content-wrapper {
    display: flex;
    flex-direction: row;
    padding-left: 5%;
    padding-right: 5%;
    width: 100%;
    min-width: 1200px;
    justify-content: center;
}

.landing-text-container {
    width: 50%;
}
.landing-text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start; /* Aligns the text to the start of the flex container */
    margin-left: 30px;
    width: 500px;
    padding-top: 20px; /* A bit of padding can be added to avoid text touching the edges */
    padding-bottom: 0px;
    padding-left: 20px;
    background-color: rgba(255, 255, 255, 0.8); /* Optional: Adds a background to the text area for better readability */
    border-radius: 10px; /* Rounds the corners */
    
}

.landing-title-div {
    margin-top: 30px;
    margin-right: 20px;
}

.landing-desc-div {
    margin-top: 40px;
    margin-bottom: 20px;
    margin-right: 20px;
}

.landing-title {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bolder;
    font-style: italic;
    font-size: 40px;
    color: #000;
}

.landing-desc {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 0.7; /* Making it a bit less bold for visual hierarchy */
    font-style: italic;
    font-size: 22px;
    color: #7c7c7c;
}

.landing-hero-action-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;

}
.landing-hero-action-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content:flex-end;
    background-color: rgba(0,0,0,0.2);
    border-top-left-radius: 5px;
    border-bottom-right-radius: 10px;
    margin-top: 10px;
    margin-bottom: 0px;
    padding: 20px;
    width: 70%;
}

.landing-hero-title {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bolder;
    font-style: italic;
    font-size: 20px;
    color: #000;
}

.landing-hero-button-container {
    display: flex;
    justify-content: center;
}

.landing-hero-button {
    font-size: 20px;
    font-weight: 700;
    padding-left: 20px;
    padding-right: 20px;
}

.landing-hero-image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
}
.landing-hero-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: rgba(255,255,255,0.8);
    padding-top: 30px;
    padding-bottom: 0px;
    padding-left: 30px;
    padding-right: 0px;
    border-radius: 10px;
}
.landing-hero-image-wrapper {
    display: flex;
    align-items: center;
    padding: 0px;
    width: 400px;
    height: 200px;
    background-size: cover;
    background-position: center; /* Centers the background image */
    background-blend-mode: overlay;
    padding: 30px;
    border-color: #000;
    border-width: 2PX;
}

.landing-hero-image {
    background-size: cover;
}