
.headerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: var(--header-vertical-padding) 20px;
    width: 100%;     /* Span the full viewport width */
    height: var(--header-height);
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

/* Transparent Header */
.headerContainer.transparent {
    position: fixed;  /* This makes the header float */
    top: 0;          /* Align to the top of the viewport */
    left: 0;         /* Align to the left of the viewport */
    z-index: 1000;   /* Ensure the header stays above all other content */
    background-color: rgba(0,0,0, 0.1); /* Semi-transparent white */
}

.headerContainer.transparent:hover {
    background-color: rgba(0, 0, 0, 0.3);
    transition: color 0.1s ease-in-out;
}

/* Solid Header */
.headerContainer.solid {
    background-color: var(--link-header-solid-bg-color);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.logo-container {
    width: 150px;
    height: 25px;
    background-size: cover;
    background-position: center; /* Centers the background image */
    background-blend-mode: overlay;
    background-image: url('../../assets/images/logo-negro.png');
}

.logo-container:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.menuContainer {
    display: flex;
    gap: 20px; /* Gap between links */
    vertical-align: bottom;
    margin-right: 30px;
    min-width: 650px;
}
.header-link-div {
    margin-right: 15px;
}

.headerContainer.transparent .header-navLink {
    color: var(--link-header-transp-text-color);
    text-decoration: none;
    padding: 5px 10px;
    border-radius: 0px;
    font-weight: bold;
}

.headerContainer.transparent .header-navLink:hover {
    background-color: var(--link-header-transp-hover-bg-color);
    color: var(--link-header-transp-hover-text-color);
    border-bottom: 1px;
    border-color: var(--link-header-transp-hover-border-color);
}

.headerContainer.solid .logo-container {
    background-image: url('../../assets/images/logo-blanco.png');
}

.headerContainer.solid .header-navLink {
    color: var(--link-header-solid-text-color);
    text-decoration: none;
    padding: 5px 10px;
    border-radius: 0px;
}

.headerContainer.solid .header-navLink:hover {
    background-color: var(--link-header-solid-hover-bg-color);
    color: var(--link-header-solid-hover-text-color);
    border-bottom: 1px;
    border-color: var(--link-header-solid-hover-border-color);
}

.header-navLink:last-of-type {
    margin-right: 0;
}

.headerContainer.solid .header-navLink.selected {
    color: rgb(0,0,0);
    background-color: transparent; 
}

.header-link-div.selected {
    background-color: rgb(200,200,200);  /* You can choose your own color/shade */
    /* You can add more styles if you want, like changing font-weight, color, etc. */
}

