.modal {
    position: fixed; /* Positioned relative to the viewport */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background-color: rgba(0, 0, 0, 0.6); /* Black background with opacity */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Sit on top */
}

.modal-content {
    background-color: #fefefe;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 50%; /* Could be more or less, depending on screen size */
    max-width: 500px; /* Maximum width */
}

.close-button {
    float: right;
    font-size: 1.5em;
    cursor: pointer;
}
