
.products-view-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 100%;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    background-size: cover; /* Ensures the background image covers the entire container */
    background-position: center; /* Centers the background image */
    background-blend-mode: overlay;
}

.product-selection-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-width: 960px;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: rgba(220,220,220,0.6);
}

.products-selector {
    display: flex;
    flex-direction: row;
    width: 80%;
    align-self: center;
    justify-self: center;
}

.products-selector-link {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-decoration: none;
    align-items: center;
    align-content: center;
}

.product-class-container {
    display: flex;
    flex-direction: column;
    width: 60%;
    min-width: 300px;
    margin-right: 10%;
    margin-left: 10%;
}

.product-class-image-div {
    display: flex;
    align-items: center;
    width: 100%;
    padding-top: 100%; /* This will ensure it's a square */
    background-size: contain;
    background-position: center;
    background-blend-mode: overlay;
    border: 1px solid  rgba(100, 100, 100, 0.6);
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
}

.product-class-title {
    width: 100%;
    height: 30px;
    margin-top: 5px; 
    color: black;
    font-size: 1.2rem;
    font-style: italic;
    text-align: center;
    font-weight: 900;
    padding-top: 5px;
}


.commitment-combo-wrapper {
    width: 80%;
    min-width: 800px;
    margin-top: 20px;
    margin-bottom: 40px;
    margin-left: 15%;
    margin-right: 10%;
}

.commitment-img {
    margin-right: 30px;
}

.commitment-combo-title {
    width: 50%;
    margin-top: 10px;
    margin-left: 50px;
    font-size: 1.6rem;
    font-weight: 900;
    font-style: normal;
}

.commitment-combo-bodytext {
    width: 80%;
    margin-top: 20px;
    margin-bottom: 10px;  /* Same as the margin-top of the .commitment-combo-title - p margin-bottom to keep centered  */
    margin-left: 80px;
    font-size: 1.4rem;
    font-weight: 500;
    text-align: justify;
}

.commitment-combo-bodytext p {
    margin-bottom: 10px;
}

.GDBAudit-info-card-wrapper {
    width: 80%;
    min-width: 800px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: rgba(112,128,144, 0.4);
    padding: 20px;
    padding-right: 40px;
}

.GDBAudit-product-icon-container {
    display: flex;
    align-items: center; /* Vertical centering */
    justify-content: center; /* Horizontal centering */
}

.GDBAudit-product-icon {
    margin-right: 0px;
    margin-left: 0px;
}

.GDBAudit-product-title {
    width: 150px;
    height: 150px;
    margin-top: 10px;
    margin-left: 20px;
    padding: 10px;
    font-size: 2rem;
    font-weight: 900;
    font-style: normal;
    text-align: center;
    background-color: lightgray;
    box-shadow: inset 0 0 0 5px white; /* This creates a 5px inner border */

    /* Flexbox for centering */
    display: flex;
    align-items: center; /* Vertical centering */
    justify-content: center; /* Horizontal centering */
}

.GDBAudit-product-description {
    width: 60%;
    margin-top: 20px;
    margin-bottom: 10px;  /* Same as the margin-top of the .commitment-combo-title - p margin-bottom to keep centered  */
    margin-left: 30px;
    margin-right: 30px;
    font-size: 1.2rem;
    font-weight: 500;
    text-align: justify;
}

.GDBAudit-product-description p {
    margin-bottom: 10px;
}

.GISConsultancy-info-card-wrapper {
    width: 80%;
    min-width: 800px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: rgba(112,128,144, 0.4);
    padding: 20px;
    padding-right: 40px;
}

.GISConsultancy-product-icon-container {
    display: flex;
    align-items: center; /* Vertical centering */
    justify-content: center; /* Horizontal centering */
}

.GISConsultancy-product-icon {
    margin-right: 0px;
    margin-left: 0px;
}

.GISConsultancy-product-title {
    width: 150px;
    height: 150px;
    margin-top: 10px;
    margin-left: 20px;
    padding: 10px;
    font-size: 1.5rem;
    font-weight: 900;
    font-style: normal;
    text-align: center;
    background-color: lightgray;
    box-shadow: inset 0 0 0 5px white; /* This creates a 5px inner border */

    /* Flexbox for centering */
    display: flex;
    align-items: center; /* Vertical centering */
    justify-content: center; /* Horizontal centering */
}

.GISConsultancy-product-description {
    width: 60%;
    margin-top: 20px;
    margin-bottom: 10px;  /* Same as the margin-top of the .commitment-combo-title - p margin-bottom to keep centered  */
    margin-left: 30px;
    margin-right: 30px;
    font-size: 1.2rem;
    font-weight: 500;
    text-align: justify;
}

.GISConsultancy-product-description p {
    margin-bottom: 10px;
}

.EnvConsultancy-info-card-wrapper {
    width: 80%;
    min-width: 800px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: rgba(112,128,144, 0.4);
    padding: 20px;
    padding-right: 40px;
}

.EnvConsultancy-product-icon-container {
    display: flex;
    align-items: center; /* Vertical centering */
    justify-content: center; /* Horizontal centering */
}

.EnvConsultancy-product-icon {
    margin-right: 0px;
    margin-left: 0px;
}

.EnvConsultancy-product-title {
    width: 150px;
    height: 150px;
    margin-top: 10px;
    margin-left: 20px;
    padding: 10px;
    font-size: 1.5rem;
    font-weight: 900;
    font-style: normal;
    text-align: center;
    background-color: lightgray;
    box-shadow: inset 0 0 0 5px white; /* This creates a 5px inner border */

    /* Flexbox for centering */
    display: flex;
    align-items: center; /* Vertical centering */
    justify-content: center; /* Horizontal centering */
}

.EnvConsultancy-product-description {
    width: 60%;
    margin-top: 20px;
    margin-bottom: 10px;  /* Same as the margin-top of the .commitment-combo-title - p margin-bottom to keep centered  */
    margin-left: 30px;
    margin-right: 30px;
    font-size: 1.2rem;
    font-weight: 500;
    text-align: justify;
}

.EnvConsultancy-product-description p {
    margin-bottom: 10px;
}



.innovation-combo-wrapper {
    width: 80%;
    min-width: 800px;
    margin-top: 20px;
    margin-bottom: 40px;
    margin-left: 15%;
    margin-right: 10%;
}

.innovation-img {
    margin-right: 30px;
}

.innovation-text-content-wrapper {
    align-items: flex-end;
}

.innovation-combo-title {
    width: 50%;
    margin-top: 10px;
    margin-right: 50px;
    font-size: 1.6rem;
    font-weight: 900;
    font-style: normal;
    text-align: right;
}

.innovation-combo-bodytext {
    width: 80%;
    margin-top: 20px;
    margin-bottom: 10px;  /* Same as the margin-top of the .innovation-combo-title - p margin-bottom to keep centered  */
    margin-right: 80px;
    font-size: 1.4rem;
    font-weight: 500;
    text-align: justify;
}

.innovation-combo-bodytext p {
    margin-bottom: 10px;
}