.userSidebarContainer {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    background-color: var(--link-sidebar-bg-color);
    box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.1);
    width: 220px;
    min-width: 220px;
    height:auto;
    min-height: calc( 100vh - var(--header-footer-sum-height));
}

.userSidebarContainer h2 {
    margin-top: 0;
    color: var(--link-sidebar-text-color);
    border-bottom: 1px solid var(--table-border-color);
    padding-bottom: 10px;
}

