:root {
    --primary-color: #0077cc;
    --hover-color: #343a40;
    --background-color: white;
    --text-color: #333;
    --table-bg-color: #ffffff;
    --table-border-color: #e0e0e0;
    --table-header-bg-color: #afafaf; /* Updated this to match the table header background color you previously had */
    --table-header-text-color: black;
    --table-row-hover-bg-color: white;
    --table-alt-row-bg-color: #f5f5f5;
    --error-color: red;
    --link-header-transp-bg-color: #000000;
    --link-header-transp-hover-bg-color: rgba(0,0,0,0.3);
    --link-header-transp-text-color: blcak;
    --link-header-transp-hover-text-color: white;
    --link-header-transp-hover-border-color: white;
    --link-header-solid-bg-color: #212529;
    --link-header-solid-hover-bg-color: rgba(200,200,200,0.3);
    --link-header-solid-text-color: rgb(255, 255, 255);
    --link-header-solid-hover-text-color: white;
    --link-sidebar-bg-color: #212529;
    --link-sidebar-hover-bg-color: #343a40;
    --link-sidebar-text-color: white;
    --link-sidebar-hover-text-color: #0077cc;

    --header-height: 30px;
    --header-vertical-padding: 10px;

    --footer-height: 50px;
    --footer-vertical-padding: 10px;

    --section-header-bg-color: rgba(101, 101, 101, 0.65);
    --section-header-text-color: white;
    --section-header-font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;

    --header-footer-sum-height: calc(var(--header-height) + 2 * var(--header-vertical-padding) + var(--footer-height) + 2 * var(--footer-vertical-padding) + 10px);

    --product-title-section-color: rgba(100,100,100,1);
}


/* Reset some default browser styles */

.landing-page-view {
    margin-top: 0;
  }
  
  .secondary-pages-view {
    margin-top: calc(var(--header-height) + 2 * var(--header-vertical-padding));
    margin-left: 0px;
    margin-right: 0px;
    min-width: min-content;
  }


body, h1, h2, h3, p {
    margin: 0;
    padding: 0;
}

body, html {
    height: 100vh;
    margin: 0;
}

body {
    font-family: Arial, sans-serif; /* Set a default font for the body */
    background-color: white; /* Light gray background for the entire app */
    color: #333; /* Default text color */
}



a {
    text-decoration: none; /* Remove underlines from all links */
    color: #0077cc; /* Default link color */
}

a:hover {
    color: #0055aa; /* Darker color for hovered links */
}

/* You can also define some utility classes */
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.center-text {
    text-align: center;
}


#root {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.main-content {
    flex: 1 0 auto; /* This ensures that main content takes up all available space */
    
}

.form-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
}

.form-field-container {
    margin: 10px;
}
.form-field-container .label {
    padding: 10px;
}
input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    align-self: flex-end;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
}

button {
    margin: 20px;
    background-color: #28a745;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
    height: 40px;
    align-self: flex-end;
}

button:hover {
    background-color: #218838;
}

.sidebarLinksContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align links to the start (or left) */
    padding-left: 10px;
}


.sidebarLinkWrapper {
    margin-bottom: 10px;
}

.sidebar-link-div {
    margin-bottom: 10px;
}

.sidebarLink {
    font-size: medium;
    color: var(--link-sidebar-text-color);
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.2s ease-in-out;
    background-color: var(--link-sidebar-bg-color);
}
.sidebarLink:hover {
    background-color: var(--link-sidebar-hover-bg-color);
    color: var(--link-sidebar-hover-text-color);
}

.sidebarLink.selected {
    margin-left: 5px;
    margin-right: 5px;
    color: rgb(0,0,0);
    background-color: transparent; 
}

.sidebar-link-div.selected {
    width: 100%;
    background-color: rgb(200,200,200);  /* You can choose your own color/shade */
    /* You can add more styles if you want, like changing font-weight, color, etc. */
}

.sidebarLink:last-of-type {
    margin-bottom: 0;
}

.management-view {
    padding: 20px;
    font-family: Arial, sans-serif;
    color: var(--text-color);
    background-color: var(--background-color);
}

.management-view form {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 30px;
    margin-bottom: 20px;
    width: 100%;
}

.management-view input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    align-self: flex-end;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 0px;
    margin-right: 0px;
}

.management-view button {
    padding-left: 20px;
    padding-right: 20px;
}

.management-view .form-field-container {
    margin-left: 20px;
    margin-right: 20px;
}

.management-view h2 {
    margin-top: 0;
}


.management-view label {
    display: block;
}

.management-view .error {
    color: var(--error-color);
}

.management-view .result {
    font-weight:lighter;
}

/* Table Styles */
.management-view table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0,0,0,0.15);
    background-color: var(--table-bg-color);
}

.management-view thead tr {
    background-color: var(--table-header-bg-color);
    color: var(--table-header-text-color);
    text-align: left;
}

.management-view th,
.management-view td {
    font-size: 11pt;
    padding: 5px 5px;
    border-bottom: 1px solid var(--table-border-color);
}

.management-view tbody tr {
    transition: background-color 0.3s ease;
}

.management-view tbody tr:hover {
    background-color: var(--table-row-hover-bg-color);
}

.management-view tbody tr:nth-of-type(even) {
    background-color: var(--table-alt-row-bg-color);
}

.welcome-header-container {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    width: 100%;
    padding: 10px;
    background-color: #afafaf;
}

.welcome-container h2 {
    margin-right: 10px;
}

.welcome-header-container {
    padding-left: 50px;
}
.welcome-summary-container {
    margin: 30px;
    background-color: rgb(220, 220, 220);
    padding: 20px;
    width: 600px;
}

.welcome-simple-data-container {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    margin: 10px;
}
.welcome-simple-data-label {
    font-size: 1.4rem;
    font-weight: 700;
    margin-right: 20px;
    width: 50%;
}

.welcome-simple-data-value {
    font-size: 1rem;
    font-weight: 700;
    margin-left: 20px;
    width: 50%;
    text-align: end;
}

.welcome-message-container {
    font-size: 1rem;
    margin-top: 30px;
}

.welcome-message-title {
    font-size: 1.5rem;
    margin-left: 30px;
    margin-bottom: 10Px;
}

.welcome-message-text {
    font-size: 1.2rem;
    margin-left: 30px;
    margin-bottom: 10Px;
}

.bottom-message-container {
    font-size: 1rem;
    margin-top: 30px;
}

.bottom-message-title {
    font-size: 1.2rem;
    margin-left: 30px;
    margin-bottom: 10Px;
}

.bottom-message-text {
    font-size: 1.2rem;
    margin-left: 30px;
    margin-bottom: 10Px;
}


.secondary-pages-container {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    background-size: cover; /* Ensures the background image covers the entire container */
    background-position: center; /* Centers the background image */
    background-blend-mode: overlay;
    margin-right: 20px;
}
.section-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: var(--section-header-bg-color);
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: var(--section-header-text-color);
    font-family: var(--section-header-font-family);
    font-size: 2vw;
    font-weight: 800;
    margin-top: 25px;
}
.secondary-page-main-body {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
    width: inherit;
}

.grecaptcha-badge { 
    visibility: hidden;
}


@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    } 
    40% {
        transform: translateY(-7px);
    } 
    60% {
        transform: translateY(-2px);
    } 
}

@media (max-width: 1000px) {
    body {
        overflow-x: auto;
    }
}

/* Set a minimum font size */
@media (max-width: 600px) {
    body {
        font-size: 12px; /* or whatever minimum size you want */
    }
}

