.admin-all-utilizations-container {
    padding: 20px;
}

.date-filter {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
}

table {
    width: 100%;
    border-collapse: collapse;
}

table, th, td {
    border: 1px solid #ddd;
}

th, td {
    padding: 8px 12px;
}

