
.login-form-container-modal {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.login-form-modal {
    display: flex;
    flex-direction: column;
    width:80%;
}
.login-input-modal {
    width: 80%;
}
