.register-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    margin: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
}

h2 {
    color: #333;
    margin-bottom: 20px;
}

.register-form {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: stretch;
    width: 100%;
}

.register-input {
    display: flex;
    padding: 10px;
    align-self: center;
    width: 100%;
}

.register-button {
    padding: 10px 15px;
    background-color: #28a745;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
    align-self: center;
    width: 100%;
}

.register-button:hover {
    background-color: #218838;
}

.error-message {
    color: red;
    margin-bottom: 10px;
}

.checkbox-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1 0 auto;
    font-size: smaller;
    width: 100%;
    color: darkgray;
    text-align: center;
}

.checkbox-container label {
    margin-left: 8px;
    text-align: left;
}

.checkbox-container a {
    color: rgb(52, 197, 245);
    font-size: smaller;
}

/* Add this to your CSS file */
.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #09f;
  
    animation: spin 1s ease infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
