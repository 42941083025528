.user-view-container {
    display: flex;
    width: 100%;
    height: 100%;
}

.user-view-container > AdminSidebar {
    flex: 1;
    flex-direction: column;
    /* Add any other styles specific to the sidebar */
}

.user-work-area {
    flex: 3;
    padding: 0px;
    /* Add any other styles specific to the working area */
}
