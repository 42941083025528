.politicadatos-view-container {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    padding-top: 30px;
}

.secondary-pages-container-container h1 {
    margin-top: 30px;
    margin-bottom: 10px;
}

.secondary-pages-container p {
    margin-top: 10px;
}

