.admin-view-container {
    display: flex;
    width: 100%;
    height: 100%;
}

.admin-view-container > AdminSidebar {
    flex: 1;
    flex-direction: column;
    height: 100%;
    /* Add any other styles specific to the sidebar */
}

.admin-work-area {
    flex: 3;
    padding: 0px;
    /* Add any other styles specific to the working area */
}
